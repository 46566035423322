import { useContext } from 'react';
import EppoLogo from 'components/logo/EppoLogo';
import { FlexBox } from 'components/style-components/FlexBox';
import Icon from 'components/ui/icon/Icon';
import UserProfileDropdown from 'components/user-profile-dropdown/UserProfileDropdown';
import { NavCollapsedContext } from 'hooks/useNavCollapsed';
import SidebarOption from './SidebarOption';
import { SidebarWrapper, SidebarToggleButton } from './styled';
interface ISidebarProp {
  options: ISideMenuOption[];
  bottomOptions?: ISideMenuOption[];
  updatesHighlightStrategy?: string;
  updatesClickHandler?: () => void;
}
export interface ISideMenuOption {
  name: string;
  url: string;
  isHidden?: boolean;
  /* Should automatically open when a submenu opens */
  forceOpen?: boolean;
  forceNewTab?: boolean;
  subOptions?: ISideMenuOption[];
}
export default function Sidebar({
  options,
  bottomOptions,
  // Below props are used for the dogfood bandit that highlights when there are new updates
  updatesHighlightStrategy,
  updatesClickHandler
}: ISidebarProp) {
  const {
    collapsed,
    toggleCollapsed
  } = useContext(NavCollapsedContext);
  return <SidebarWrapper>
      <FlexBox justifyContent={collapsed ? 'center' : 'space-between'} alignItems="flex-start" padding="4px 0 0" height="32px">
        {!collapsed && <FlexBox padding="0 0 0 4px">
            <EppoLogo color="light" height="28px" />
          </FlexBox>}
        <SidebarToggleButton onClick={toggleCollapsed}>
          <Icon name="First page arrow.first_page" style={{
          transform: collapsed ? 'rotate(180deg)' : undefined
        }} />
        </SidebarToggleButton>
      </FlexBox>

      <FlexBox flex={1} flexDirection="column" gap="24px" margin="40px 0 0">
        {options.filter(({
        isHidden
      }) => !isHidden).map(({
        name,
        url,
        subOptions
      }: ISideMenuOption) => <SidebarOption key={url} url={url} name={name} subOptions={subOptions} />)}
      </FlexBox>

      <UserProfileDropdown />

      {!!bottomOptions?.length && <FlexBox flexDirection="column" gap="16px" margin="16px 0 0">
          {bottomOptions.map(({
        name,
        url,
        forceNewTab
      }) => {
        let highlightStrategy = undefined;
        if (name.toLowerCase() === 'updates' && updatesHighlightStrategy) {
          highlightStrategy = updatesHighlightStrategy;
        }
        return <SidebarOption key={url} url={url} name={name} forceNewTab={forceNewTab} highlightStrategy={highlightStrategy} additionalClickHandler={updatesClickHandler} />;
      })}
        </FlexBox>}
    </SidebarWrapper>;
}