import {
  COLOR_BORDER,
  COLOR_ERROR,
  TEAM_ICON_BACKGROUND,
  TRANSFORM_BLACK_TO_BODY,
  TRANSFORM_BLACK_TO_WHITE,
} from 'constants/colors';
import { FONT_SIZE_REGULAR, FONT_SIZE_SMALL } from 'constants/typography';

import styled from '@emotion/styled';
import Link from 'next/link';
import { borderRadius, spacing } from 'theme';

import { FlexBox } from 'components/style-components/FlexBox';

import { colors } from 'theme/colors';

export const NoTeamsWrapper = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  margin: '32px 0 ',
  width: '100%',
  textAlign: 'center',

  p: {
    margin: '16px auto',
    maxWidth: '440px',
  },
});

export const TeamsListHeaderControls = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const StyledTeamLink = styled(Link)({
  color: colors.text.primary,
  textDecoration: 'none',

  '&:hover div': {
    color: colors.text.link,
    textDecoration: 'none',
  },
});

export const TeamTitleDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.xs,
});

export const TeamIconBackground = styled.div<{ size?: string }>(({ size = '24' }) => ({
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: '50%',
  backgroundColor: TEAM_ICON_BACKGROUND,
}));

export const TeamIconImg = styled.img<{ size?: string }>(({ size = '24' }) => ({
  width: `${size}px`,
  height: `${size}px`,
  filter: TRANSFORM_BLACK_TO_WHITE,
  verticalAlign: 'unset',
  display: 'block',
}));

export const NoMembers = styled.div({
  fontStyle: 'italic',
});

export const TeamMemberRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '4px',

  '> div:not(:last-child)': {
    marginLeft: '-4px',
  },
});

export const TeamMemberExtra = styled.div({
  marginLeft: '8px',
});

export const Check = styled.img({
  filter: TRANSFORM_BLACK_TO_BODY,
});

export const TeamModalFormInput = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',

  label: {
    height: 24,
    lineHeight: '24px',
    color: colors.text.secondary,
    textTransform: 'uppercase',
    fontSize: FONT_SIZE_SMALL,
  },
});

export const TeamModalFormGroup = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
});

export const TeamModalError = styled.div({
  margin: '4px 0 0 34px',
  minHeight: '20px',
  fontSize: FONT_SIZE_REGULAR,
  color: COLOR_ERROR,
});

export const TeamDetailsTitleBannerWrapper = styled.div({
  marginBottom: '16px',
  padding: '16px 0',
  borderBottom: `1px solid ${COLOR_BORDER}`,
});

export const TeamListWrapper = styled(FlexBox)({
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: colors.surface.white,
  maxWidth: '480px',
  border: `1px solid ${colors.border.primary}`,
  borderRadius: borderRadius.md,

  '& > span': {
    display: 'block', // team title is a span because Text component is used
    width: '100%',
    padding: spacing.sm,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.border.primary}`,
    },
  },
});
