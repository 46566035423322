import { FormEvent, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { DevOnlyLoginMethod, DevOnlyRoleSelector } from 'components/login/DevOnlyRoleSelector';
import { LoginForm } from 'components/login/LoginForm';
import eppoLogo from './img/eppo-logo-purple.svg';
import { LoginPageColumn, LoginPageLayout, LoginPageWrapper, LogoWrapper } from './styled';
export default function LoginPage() {
  const [loginMethod, setLoginMethod] = useState<DevOnlyLoginMethod>('auth0');
  const router = useRouter();
  const next = router.query['next'];
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL || '';

  // If path contains ?next= query param, pass it on to Auth0 for post-login redirection.
  const loginUrl = `${baseUrl}/login${next ? `?next=${next}` : ''}`;
  const shouldHandleAuth0Login = process.env.NEXT_PUBLIC_APP_ENV !== 'dev' || loginMethod === 'auth0';
  const handleDevOnlyFormSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/local-dev/dev-login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        companyId: 1,
        role: loginMethod
      })
    });
    if (res.status === 200) {
      window.location.href = `${process.env.NEXT_PUBLIC_BASE_URL}/experiments`;
    } else {
      const payload = await res.json();
      const message = payload.errorMessage ?? 'Unknown error';
      alert(message);
    }
  }, [loginMethod]);
  return <LoginPageLayout>
      {/* TODO: Replace the old logo with <EppoLogo color="dark" height="32px" /> once we publicly rollout the Rebrand M1. */}
      <LogoWrapper>
        <img src={eppoLogo} alt="Eppo" />
      </LogoWrapper>
      <LoginPageWrapper>
        <LoginPageColumn>
          <DevOnlyRoleSelector onChange={value => {
          setLoginMethod(value);
        }} loginMethod={loginMethod} />
          {shouldHandleAuth0Login ? <LoginForm submitType="link" loginUrl={loginUrl} /> : <form onSubmit={handleDevOnlyFormSubmit}>
              <LoginForm submitType="form" />
            </form>}
        </LoginPageColumn>
      </LoginPageWrapper>
    </LoginPageLayout>;
}