import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { IAssignmentEvent, IAssignmentLogger, init, precomputedInit } from '@eppo/js-client-sdk';
import { shouldUsePrecomputedFlags } from 'components/feature-flag/hooks/useFlagAssignment';
import augmentSubjectAttributes from 'lib/augment-subject-attributes';
import { isDatadogRumInitialized } from 'helpers/datadog-helper';
import { isDevelopmentEnv, isQAEnv } from 'helpers/env-helper';
import { useUser } from 'hooks/useUser';
interface IEppoFeatureFlagProvider {
  waitForInitialization?: boolean;
  children: JSX.Element;
  loadingComponent?: JSX.Element;
}
export default function EppoFeatureFlagProvider({
  waitForInitialization = false,
  children,
  loadingComponent = <div>Loading...</div>
}: IEppoFeatureFlagProvider): JSX.Element {
  const subjectAttributes = useMemo(() => ({}), []);
  const userId = augmentSubjectAttributes(subjectAttributes, useUser());
  const subjectKey = String(userId);
  const [hasCompletedInitRequest, setHasCompletedInitRequest] = useState(false);
  const assignmentLogger: IAssignmentLogger = useMemo(() => ({
    logAssignment(assignment: IAssignmentEvent) {
      // Send the feature flag when Eppo reports the exposure
      if (isDatadogRumInitialized()) {
        // datadog does not accept `null` values
        datadogRum.addFeatureFlagEvaluation(assignment.featureFlag, assignment.variation ?? 'NULL');
      }
    }
  }), []);
  useEffect(() => {
    // Don't gracefully swallow SDK errors in development and QA environments
    const gracefulFailureMode = !isDevelopmentEnv() && !isQAEnv();
    if (shouldUsePrecomputedFlags) {
      precomputedInit({
        apiKey: process.env.NEXT_PUBLIC_FEATURE_FLAG_API_KEY ?? '',
        subjectKey: subjectKey,
        subjectAttributes: subjectAttributes,
        assignmentLogger: assignmentLogger
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(precomputedClient => {
        // TODO: add method to set the failure mode, i.e. precomputedClient.setIsGracefulFailureMode(gracefulFailureMode);
        return setHasCompletedInitRequest(true);
      }).catch(error => {
        datadogRum.addError(error);
        if (gracefulFailureMode) {
          console.error('Error initializing Eppo SDK', error);
        }
        return setHasCompletedInitRequest(true);
      });
    } else {
      init({
        apiKey: process.env.NEXT_PUBLIC_FEATURE_FLAG_API_KEY ?? '',
        assignmentLogger: assignmentLogger,
        /*
        This behavior is called "freshest cache on the next session".
         During initialization, the SDK will simultaneously load the cache from disk and
        fetch from the server.
         If there are cached entries found and loading from localStorage wins the race
        (not guaranteed since it is slow and the CDN is fast),
        the init method will return immediately without waiting on the network request
        to complete.
         The user will be served the last session's network loaded configuration for this session.
         Meanwhile the network request completes and updates the cache. On the next session these
        updated values will be used after they are loaded from the cache.
         This strategy balances the fastest possible SDK initialization while
        relaxing the requirement of having the latest configuration at the start of each session.
        */
        maxCacheAgeSeconds: 0,
        useExpiredCache: true,
        updateOnFetch: 'empty'
      }).then(client => {
        client.setIsGracefulFailureMode(gracefulFailureMode);
        return setHasCompletedInitRequest(true);
      }).catch(error => {
        datadogRum.addError(error);
        if (gracefulFailureMode) {
          console.error('Error initializing Eppo SDK', error);
        }
        return setHasCompletedInitRequest(true);
      });
    }
  }, [subjectKey, subjectAttributes, assignmentLogger]);
  return <FeatureFlagContext.Provider value={{
    isInitialized: hasCompletedInitRequest
  }}>
      {!waitForInitialization || hasCompletedInitRequest ? children : loadingComponent}
    </FeatureFlagContext.Provider>;
}

// Create a context for the initialization state
export const FeatureFlagContext = createContext({
  isInitialized: false
});
export function useFeatureFlagInitialized() {
  return useContext(FeatureFlagContext).isInitialized;
}