import React, { useContext } from 'react';
import { IntercomProvider as IntercomProviderBase } from 'react-use-intercom';
import { UserContext } from 'lib/user-context';
export const IntercomProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const appId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
  const userInfo = useContext(UserContext);
  return appId && userInfo?.userId ? <IntercomProviderBase appId={appId} autoBoot autoBootProps={{
    email: userInfo.email,
    company: {
      companyId: `${userInfo.currentCompany.companyId}`,
      name: `${userInfo.currentCompany.name}`,
      createdAt: userInfo.currentCompany.createdAt,
      website: userInfo.currentCompany.primaryDomain?.domain
    }
  }}>
      {children}
    </IntercomProviderBase> : <IntercomProviderBase appId="" autoBoot={false}>
      {children}
    </IntercomProviderBase>;
};