export enum EnvironmentEnum {
  Development = 'dev',
  QA = 'qa',
  Production = 'prod',
  Preview = 'preview',
}

export function getEnvironment(): EnvironmentEnum {
  return process.env.NEXT_PUBLIC_APP_ENV as EnvironmentEnum;
}

export function isDevelopmentEnv(): boolean {
  return [EnvironmentEnum.Development, 'development', undefined, null].includes(
    process.env.NEXT_PUBLIC_APP_ENV,
  );
}

export function isQAEnv(): boolean {
  return [EnvironmentEnum.QA].includes(getEnvironment());
}

export function isProductionEnv(): boolean {
  return [EnvironmentEnum.Production].includes(getEnvironment());
}

export function isPreviewEnv(): boolean {
  return [EnvironmentEnum.Preview].includes(getEnvironment());
}
