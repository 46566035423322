export function stripMarkdown(text: string): string {
  return text.replace(
    /(\*\*|__)(.*?)\1|(\*|_)(.*?)\3|`([^`]+)`|~~(.*?)~~|!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)|<[^>]+>|#+\s*(.*?)(\n|$)|\n/g,
    '$2$4$5$6$7',
  );
}

export function containsMarkdown(text: string): boolean {
  return /(\*\*|__|\*|_|`|~~|!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)|<[^>]+>|#+\s*.*?(\n|$))/.test(text);
}
