import { colors } from 'theme';
import { FlexBox } from 'components/style-components/FlexBox';
import Text from 'components/ui/typography/Text';
export interface IFormFieldHelperTextProps {
  helperText?: string;
  errorText?: string;
  state?: 'error';
}

/**
 * A primitive UI component that displays either helper text or error text, intended for reuse across input fields.
 *
 * This component helps in showing informative helper text when there’s no error, and an error message when the input state
 * is marked as 'error'.
 *
 * @example
 *
 * ```tsx
 * <FormFieldHelperText
 *   helperText="This is a helper text."
 *   errorText=""
 *   state={undefined}
 * />
 * ```
 *
 */

const FormFieldHelperText = ({
  helperText,
  errorText,
  state
}: IFormFieldHelperTextProps) => {
  return <FlexBox flexDirection="column" justifyContent="flex-end">
      {errorText && state === 'error' ? <Text color={colors.text.error}>{errorText}</Text> : <Text color={colors.text.secondary} variant="body-small">
          {helperText}
        </Text>}
    </FlexBox>;
};
export default FormFieldHelperText;