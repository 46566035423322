import { datadogRum } from '@datadog/browser-rum';
import { init as commandBarInit } from 'commandbar';
import { fetchQuery, graphql } from 'relay-runtime';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';

import { isDatadogRumInitialized } from './datadog-helper';
import { rudderanalytics } from './rudderstack-helper';

import { initializeThirdPartyTools_User_Query } from './__generated__/initializeThirdPartyTools_User_Query.graphql';

let isInitialized = false;

export default async function initializeThirdPartyTools(
  userId: number,
  environment: RelayModernEnvironment,
) {
  if (isInitialized) {
    return;
  }

  const { user } =
    (await fetchQuery<initializeThirdPartyTools_User_Query>(
      environment,
      graphql`
        query initializeThirdPartyTools_User_Query($userId: Int!) {
          user(userId: $userId) {
            id
            userId
            email
            name
            createdAt
            userCompanyRelations {
              company {
                id
                name
                companyId
                primaryDomain {
                  domain
                }
                createdAt
              }
            }
          }
        }
      `,
      { userId },
    ).toPromise()) ?? {};

  const commandBarOrgId = process.env.NEXT_PUBLIC_COMMANDBAR_ORG_ID;
  if (commandBarOrgId) {
    commandBarInit(commandBarOrgId);
  }

  if (user) {
    const company = user.userCompanyRelations[0].company;
    try {
      if (rudderanalytics) {
        rudderanalytics.identify(user.userId.toString(), {
          email: user.email,
          name: user.name,
          createdAt: user.createdAt,
          company: {
            id: company.companyId,
            name: company.name,
          },
        });
      }

      if (isDatadogRumInitialized()) {
        datadogRum.setUser({
          id: user.id,
          email: user.email,
          name: user.name,
          createdAt: user.createdAt,
          company: {
            id: company.companyId,
            name: company.name,
          },
        });
      }
    } catch {
      console.error('Could not initialize scripts.');
    }
  }
  isInitialized = true;
}
