import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { borderRadius, spacing } from 'theme';

import { zIndexOffcanvas } from 'styles/z-index';

import { colors } from 'theme/colors';

const sidebarSlideIn = keyframes`
    from {
      transform: translateX(100%)
    }
    100% {
      transform: translateX(0)
    }
`;

const bottomsSidebarSlideIn = keyframes`
    from {
      transform: translateY(100%)
    }
    100% {
      transform: translateY(0)
    }
`;
export const SidebarContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  right: 0,
  height: '100vh',
  zIndex: zIndexOffcanvas,
  pointerEvents: 'none',
  color: colors.text.primary,
}));

export const BottomSidebarContainer = styled.div<{ animate: boolean }>(({ animate }) => ({
  position: 'absolute',
  left: spacing.lg,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.surface.white,
  zIndex: zIndexOffcanvas,
  pointerEvents: 'all',
  color: colors.text.primary,
  borderTopLeftRadius: borderRadius.md,
  borderTopRightRadius: borderRadius.md,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1), inset 1px 0px 0px #EAEAEA',
  animation: animate ? `${bottomsSidebarSlideIn} 0.4s ease` : '',
}));

export const SidebarDiv = styled.div<{ animate: boolean }>(({ animate }) => ({
  height: '100vh',
  width: '100%',
  background: colors.surface.white,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1), inset 1px 0px 0px #EAEAEA', // this is a color not in constants
  animation: animate ? `${sidebarSlideIn} 0.4s ease` : '',
  overflowY: 'auto',
  position: 'relative', // This is needed to allow absolutely positioned descendants to be scrolled.
}));

export const BottomSidebarDiv = styled.div({
  position: 'relative',
  backgroundColor: colors.surface.white,
  transform: 'translateY(0)',
  width: '784px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderTopLeftRadius: borderRadius.md,
  borderTopRightRadius: borderRadius.md,
});
