/**
 * @generated SignedSource<<d11943c921ed75c4dadb961026bfa7e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DatabaseType = "BIG_QUERY" | "DATABRICKS" | "REDSHIFT" | "SNOWFLAKE";
export type UserRoleEnum = "Admin" | "DataOwner" | "ExperimentEditor" | "Viewer";
export type authorizeUserQuery$variables = Record<PropertyKey, never>;
export type authorizeUserQuery$data = {
  readonly userContext: {
    readonly currentCompany: {
      readonly companyId: number;
      readonly config: {
        readonly hideDefinitionSqlRows: boolean;
      };
      readonly createdAt: string;
      readonly databaseConnections: ReadonlyArray<{
        readonly databaseType: DatabaseType;
      }>;
      readonly name: string;
      readonly primaryDomain: {
        readonly domain: string;
      } | null;
    };
    readonly email: string;
    readonly permissions: ReadonlyArray<string>;
    readonly role: UserRoleEnum;
    readonly teams: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly userId: number;
  };
};
export type authorizeUserQuery = {
  response: authorizeUserQuery$data;
  variables: authorizeUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyConfigOutput",
  "kind": "LinkedField",
  "name": "config",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hideDefinitionSqlRows",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Team",
  "kind": "LinkedField",
  "name": "teams",
  "plural": true,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "authorizeUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContext",
        "kind": "LinkedField",
        "name": "userContext",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "currentCompany",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DatabaseConnection",
                "kind": "LinkedField",
                "name": "databaseConnections",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AllowedDomain",
                "kind": "LinkedField",
                "name": "primaryDomain",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "authorizeUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserContext",
        "kind": "LinkedField",
        "name": "userContext",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "currentCompany",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DatabaseConnection",
                "kind": "LinkedField",
                "name": "databaseConnections",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AllowedDomain",
                "kind": "LinkedField",
                "name": "primaryDomain",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b17bd5d635e7563af1dbfa4f3d4fc4c0",
    "id": null,
    "metadata": {},
    "name": "authorizeUserQuery",
    "operationKind": "query",
    "text": "query authorizeUserQuery {\n  userContext {\n    userId\n    email\n    role\n    currentCompany {\n      companyId\n      name\n      databaseConnections {\n        databaseType\n        id\n      }\n      config {\n        hideDefinitionSqlRows\n      }\n      primaryDomain {\n        domain\n        id\n      }\n      createdAt\n      id\n    }\n    permissions\n    teams {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "22e7ff8eb0687780ab3a8ecadf87e91f";

export default node;
