import React from 'react';
import { COLOR_WHITE } from 'constants/colors';
import styled from '@emotion/styled';
import Link from 'next/link';
import { LoginPageForm, LoginPageFormContainerP, LoginPageFormWrapper, LoginPageHref } from 'components/login/styled';
import Heading from 'components/ui/typography/Heading';
type Auth0LoginProps = {
  submitType: 'link';
  loginUrl: string;
};
type DevRoleLoginProps = {
  submitType: 'form';
};
export const LoginForm: React.FunctionComponent<DevRoleLoginProps | Auth0LoginProps> = props => {
  const termsUrl = `https://app.termly.io/document/terms-of-use-for-saas/4c635cc8-24f6-4c05-83fa-0382fca756ce`;
  const privacyPolicyUrl = `https://app.termly.io/document/privacy-policy/a555478b-524f-4b53-b70e-6575d94ad3c7`;
  return <LoginPageFormWrapper>
      <Heading variant="h3">Log in or Sign up</Heading>
      <LoginPageForm>
        {props.submitType === 'link' && <Link href={props.loginUrl} prefetch={false}>
            <LoginButton>Continue to Eppo</LoginButton>
          </Link>}
        {props.submitType === 'form' && <LoginButton type="submit">Continue to Eppo</LoginButton>}
        <LoginPageFormContainerP>
          By continuing, you agree to Eppo’s{' '}
          <LoginPageHref href={termsUrl} target="_blank">
            Terms of Use
          </LoginPageHref>
          . Read our{' '}
          <LoginPageHref href={privacyPolicyUrl} target="_blank">
            Privacy Policy.
          </LoginPageHref>
        </LoginPageFormContainerP>
      </LoginPageForm>
    </LoginPageFormWrapper>;
};
const LoginButton = styled.button({
  width: '336px',
  height: '48px',
  fontSize: '13px',
  color: COLOR_WHITE,
  backgroundColor: '#6C55D4',
  // matches the logo. refactor after M1 launch
  borderRadius: '8px',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 12px'
});